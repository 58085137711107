<template>
    <section class="payment-record">
        <div class="top-bar bg-white" style="border-radius: 10px">
            <div style="width: 100%;margin-top:20px">
                <el-form ref="formSearch" size="small" :model="formSearch" inline>
                    <span style="margin: 0 5px;">账单搜索 :</span>
                                <el-select size="small" v-model="formSearch.keyWordCode" placeholder="请选择"
                                           style="width: 200px;margin: 0 5px">
                                    <el-option label="全部" :value="0"/>
                                    <el-option label="账单号" :value="1"/>
                                    <!-- <el-option label="合同号" :value="2"/> -->
                                    <el-option label="座落" :value="3"/>
                                    <el-option label="租户姓名" :value="4"/>
                                    <el-option label="租户电话" :value="5"/>
                                </el-select>
                                <el-input v-model="formSearch.keyWord" style="width: 200px;margin: 0 5px;" size="small"
                                          placeholder="请输入内容"/>
                                <span style="margin: 0 5px;">租户类型 :</span>
                                <el-select size="small" v-model="formSearch.leasorType" style="width: 200px;margin: 0 5px"
                                           placeholder="请选择">
                                    <el-option label="全部" :value="0"/>
                                    <el-option label="法人" value="法人"/>
                                    <el-option label="个人" value="个人"/>
                                </el-select>
                                <span style="margin: 0 5px;">收费类型 :</span>
                                <el-select size="small" v-model="formSearch.billType" style="width: 200px;margin: 0 5px"
                                           placeholder="请选择">
                                    <el-option label="全部" :value="0"/>
                                    <el-option v-for="(item,index) in billTypes" :key="index" :label="item.codeName"
                                               :value="item.code"/>
                                </el-select>
                                <span style="margin: 0 5px;">缴费状态 :</span>
                                <el-select size="small" v-model="formSearch.billStatus" style="width: 200px;margin: 0 5px"
                                           placeholder="请选择">
                                    <el-option label="全部" :value="0"/>
                                    <el-option v-for="(item,index) in billStatusTypes" :key="index"
                                               :label="item.codeName" :value="item.code"/>
                                </el-select>
                                <span style="margin: 0 5px;">支付渠道 :</span>
                              <el-select size="small" v-model="formSearch.paymentChannel" placeholder="请选择"
                                         style="width: 205px;margin: 0 5px">
                                <el-option label="全部" :value="0"/>
                                <el-option v-for="(item,index) in paymentChannelTypes" :key="index"
                                           :label="item.codeName" :value="item.codeName"/>
                              </el-select>
                              <div style="display:flex;">
                                <div style="margin-top:20px">
                                <span style="margin: 10px 5px;">账单开始时间 :</span>
                                <el-date-picker v-model="billStartAndEndDate" type="daterange" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" size="small" style="width: 300px;margin-right: 10px"
                                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
                                        
                              </div>
                              <div style="margin-top:20px">
                                <span style="margin: 10px 5px;">账单结束时间 :</span>
                                <el-date-picker v-model="billEndAndEndDate" type="daterange" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" size="small" style="width: 300px;margin-right: 10px"
                                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
                               </div>
                               <div style="margin-top:20px">
                                <span style="margin: 0 5px;">收款时间 :</span>
                                <el-date-picker v-model="startAndEndDate" type="daterange" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" size="small" style="width: 300px;margin-right: 10px"
                                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                </el-date-picker>
                               </div>
                               <div style="justify-content: right;margin: 20px 10px" class="flex">
                                    <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
                                </div>  
                                <div style="justify-content: right;margin: 20px 10px;" class="flex">
                                    <el-button size="small" @click="handleReset">重置</el-button>
                                </div>
                                <div style="justify-content: right;margin: 20px 10px;" class="flex">
                                    <el-button type="primary" size="small" @click="paybillRentalExport">导出</el-button>
                                </div>
                                
                              </div>
                              <div v-if="getButtonMenu('BATCH_CANCLE_BILL_BUTTON')"  style="margin:20px 0px">
                                    <el-link type="primary" :underline="false" @click="batchWriteOff">批量核销</el-link>
                            </div>
                </el-form>
            </div>
        </div>
        <div class="top-bar bg-white" style="border-radius: 10px">
            <r-e-table class="bg-white" ref="billTableRef" :dataRequest="getPaybillList" :columns="billTableColumn"
                       :query="formSearch" :height="540" :notAuto="true" show-summary :summary-method="getSummaries"
                       @selection-change="handleSelectionChange">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="look(row)">详情</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>

        <layer-batch-write-off ref="layerBatchWriteOff" @clickCancel="handleSearch"/>
    </section>
</template>

<script>
    import {getPaybillList, paybillRentalExport} from "@/api/bill";
    import {billTableColumn} from "@/views/bill-management/rent-withdrawal/data";
    import {downloadByData} from "@/utils/hooks/download";
    import {timeFormat, numberFormat} from "@custom";
    import {buttonCookies} from "@storage/cookies";
    import {getAddressList} from "@/api/community-management";

    export default {
        name: "payment-record",
        data() {
            return {
                value: null,
                startAndEndDate: [],
                billTableColumn,
                formSearch: {
                    keyWordCode: 0,
                    keyWord: "",
                    billType: 0,
                    leasorType: 0,
                    apartmentParentType: 0,
                    apartmentType: 0,
                    billStatus: 0,
                    paymentChannel: 0,
                    paidStartDate: "",
                    paidEndDate: "",
                    billStartStartDate: "",// 账单开始时间开始
                    billEndStartDate: "", // 账单开始时间结束
                    district: null,
                },
                billTypes: [],
                houseTypes: [],
                billStatusTypes: [],
                paymentChannelTypes: [],
                billStartAndEndDate: [], //账单开始时间
                billEndAndEndDate:[],   //账单结束时间
                multipleSelection: [],
                options: []
            };
        },
        components: {
            layerBatchWriteOff: () => import("./components/layer-batch-write-off"),
        },
        methods: {
            getPaybillList(params) {
                const formSearch = this.formSearch;
                const paramsData = {...params, ...formSearch}
                return getPaybillList(paramsData);
            },
            handleSearch() {
                this.$refs["billTableRef"].getTableData();
            },
            handleReset() {
                this.$refs["formSearch"].resetFields();
                this.formSearch = {
                    keyWordCode: 0,
                    keyWord: "",
                    billType: 0,
                    leasorType: 0,
                    apartmentParentType: 0,
                    apartmentType: 0,
                    billStatus: 0,
                    paymentChannel: 0,
                    paidStartDate: "",
                    paidEndDate: "",
                    billStartStartDate: "", //账单开始时间开始
                    billEndStartDate: "", //账单开始时间结束
                    district: null,
                };
                //billStartDate  billEndDate
                this.startAndEndDate = null;
                this.billStartAndEndDate = null;
                this.billEndAndEndDate = null
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            look(data) {
              console.log(data)
                this.$router.push({name: "bill-details", params: {data}});
            },
            paybillRentalExport() {
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                const {formSearch} = this;
                const page = this.$refs["billTableRef"].pNumber;
                const pageSize = this.$refs["billTableRef"].pSize;
                const paramsData = {...formSearch, page, pageSize}
                paybillRentalExport(paramsData).then(res => {
                    downloadByData({data: res, filename: `账单管理${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
                }).finally(() => loading.close());
            },
            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                columns.forEach((column, index) => {
                    console.log('index',index);
                    if (index === 0) {
                        sums[index] = '合计:';
                        return;
                    }
                    if (index === 1) {
                        sums[index] = '';
                        return;
                    }
                    if ( index === 13 || index === 14) {
                        const values = data.map(item => Number(item[column.property]));
                        if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            console.log('value',value);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0);
                        sums[index] = numberFormat(sums[index] / 100);
                    }
                    }
                   
                else sums[index] = '';
                });
                return sums;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            batchWriteOff() {
                let uuids = this.multipleSelection.map(({uuid}) => uuid);
                this.$refs["layerBatchWriteOff"].openDialog({uuids: uuids.join(";")});
            },
            getButtonMenu(menu) {
                return JSON.parse(buttonCookies.get()).map(({url}) => url).some(v => v === menu);
            },
        },
        watch: {
            startAndEndDate(value) {
                if (value && value.length !== 0) {
                    let [paidStartDate, paidEndDate] = value;
                    this.formSearch.paidStartDate = paidStartDate + " 00:00:00";
                    this.formSearch.paidEndDate = paidEndDate + " 23:59:59";
                } else {
                    this.formSearch.paidStartDate = null;
                    this.formSearch.paidEndDate = null;
                }
            },
            async 'formSearch.apartmentParentType'(value) {
                if (value !== 0) {
                    this.formSearch.apartmentType = 0;
                    this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
                } else {
                    this.formSearch.apartmentType = 0;
                    this.houseTypes = [];
                }
            },
            billStartAndEndDate(value) {
                if (value && value.length !== 0) {
                    let [billStartStartDate, billEndStartDate] = value;
                    this.formSearch.billStartStartDate = billStartStartDate + " 00:00:00";
                    this.formSearch.billEndStartDate = billEndStartDate + " 23:59:59";
                } else {
                    this.formSearch.billStartStartDate = null;
                    this.formSearch.billEndStartDate = null;
                }
            },
            billEndAndEndDate(value) {
                if (value && value.length !== 0) {
                    let [billStartEndDate, billEndEndDate] = value;
                    this.formSearch.billStartEndDate = billStartEndDate + " 00:00:00";
                    this.formSearch.billEndEndDate = billEndEndDate + " 23:59:59";
                } else {
                    this.formSearch.billStartEndDate = null;
                    this.formSearch.billEndEndDate = null;
                }
            },
        },
        async mounted() {
            this.billStatusTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 600600);
            this.paymentChannelTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 500100);
            this.billTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 500500);
            let res = await getAddressList();
            this.options = res.list;

            const {params: {billType, billStatus, startAndEndDate, data}} = this.$route;
            if (billType) this.formSearch.billType = billType;
            if (billStatus) this.formSearch.billStatus = billStatus;
            if (startAndEndDate && startAndEndDate.length !== 0) {
                this.billStartAndEndDate = startAndEndDate;
                this.formSearch.billStartStartDate = startAndEndDate[0] + " 00:00:00";
                this.formSearch.billEndStartDate = startAndEndDate[1] + " 23:59:59";
            }
            if (data) {
                const {stateCode, type, startDate, endDate, paymentChannel, paidDate, keyWordCode, keyWord, month, houseType, amountType} = data;
                if (type) this.formSearch.billType = parseInt(type);
                if (stateCode) this.formSearch.billStatus = stateCode;
                if (paymentChannel) this.formSearch.paymentChannel = paymentChannel;
                if (paidDate) this.startAndEndDate = [timeFormat(new Date(paidDate)), timeFormat(new Date(paidDate))];
                if (startDate && endDate) this.billStartAndEndDate = [timeFormat(new Date(startDate)), timeFormat(new Date(endDate))];
                if (keyWordCode) this.formSearch.keyWordCode = keyWordCode;
                if (keyWord) this.formSearch.keyWord = keyWord;
                if (month) this.formSearch.month = month;
                if (houseType) this.formSearch.houseType = houseType;
                if (amountType >= 0) this.formSearch.amountType = amountType;
            }
            this.handleSearch();
        },
    }
</script>

<style lang="scss" scoped>
    .payment-record {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>
<style>
    .el-date-editor .el-range-separator {
        width: 10% !important;
    }
</style>